import React from "react";
import App from "../components/App";

const NotFoundPage = () => (
  <App>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </App>
);

export default NotFoundPage;
